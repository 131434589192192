export default function LoadingIndicator({ width = 50, theme = "light", showText = true }) {
    const yellowFill = "#febe10";
    const secondFill = theme.toLowerCase() === "light" ? "#142533" : "white";

    return (
        <div data-html2canvas-ignore="true" className={"d-flex flex-column align-items-center m-auto"}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={width} viewBox="0 0 100 100" enableBackground="new 0 0 100 100">
                <rect x={10} fill={secondFill} width={10} height={70} transform="translate(0) rotate(180 10 50)">
                    <animate attributeName="height" attributeType="XML" dur={1.5} values="20; 70; 20" repeatCount="indefinite" begin={0}></animate>
                </rect>
                <rect x={0} fill={yellowFill} width={10} height={20} transform="translate(0) rotate(0 0 50)">
                    <animate attributeName="height" attributeType="XML" dur={1.5} values="70; 20; 70" repeatCount="indefinite" begin={0}></animate>
                </rect>
                <rect x={32.5} fill={secondFill} width={10} height={40} transform="translate(0) rotate(180 32.5 50)">
                    <animate attributeName="height" attributeType="XML" dur={1.5} values="40; 50; 40" repeatCount="indefinite" begin={0.9}></animate>
                </rect>
                <rect x={22.5} fill={yellowFill} width={10} height={50} transform="translate(0) rotate(0 0 50)">
                    <animate attributeName="height" attributeType="XML" dur={1.5} values="50; 40; 50" repeatCount="indefinite" begin={0.9}></animate>
                </rect>
                <rect x={55} fill={secondFill} width={10} height={80} transform="translate(0) rotate(180 55 50)">
                    <animate attributeName="height" attributeType="XML" dur={1.5} values="10; 80; 10" repeatCount="indefinite" begin={0.5}></animate>
                </rect>
                <rect x={45} fill={yellowFill} width={10} height={10} transform="translate(0) rotate(0 0 50)">
                    <animate attributeName="height" attributeType="XML" dur={1.5} values="80; 10; 80" repeatCount="indefinite" begin={0.5}></animate>
                </rect>
                <rect x={77.5} fill={secondFill} width={10} height={60} transform="translate(0) rotate(180 77.5 50)">
                    <animate attributeName="height" attributeType="XML" dur={1.5} values="30; 60; 30" repeatCount="indefinite" begin={0.3}></animate>
                </rect>
                <rect x={67.5} fill={yellowFill} width={10} height={30} transform="translate(0) rotate(0 0 50)">
                    <animate attributeName="height" attributeType="XML" dur={1.5} values="60; 30; 60" repeatCount="indefinite" begin={0.3}></animate>
                </rect>
                <rect x={80} fill={secondFill} width={10} height={60} transform="translate(0) rotate(180 90 50)">
                    <animate attributeName="height" attributeType="XML" dur={1.5} values="30; 60; 30" repeatCount="indefinite" begin={0.8}></animate>
                </rect>
                <rect x={90} fill={yellowFill} width={10} height={30} transform="translate(0) rotate(0 0 50)">
                    <animate attributeName="height" attributeType="XML" dur={1.5} values="60; 30; 60" repeatCount="indefinite" begin={0.8}></animate>
                </rect>
            </svg>
            {showText && <span style={{ fontSize: width / 3.6 + "px" }}>Loading</span>}
        </div>
    );
}
