import { useRef, useState } from "react";
import { Button, Form, InputGroup, Stack } from "react-bootstrap";
import { LockFill, PersonFill } from "react-bootstrap-icons";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../../auth/Auth";
import { ReactComponent as LogoTitle } from "../../assets/Logo_in_svg.svg";

export default function LoginForm() {
    const { onLogin } = useAuth();
    const [error, setError] = useState(null);
    const [logggingIn, setLogggingIn] = useState(false);
    const formRef = useRef();
    const [capsLockOn, setCapsLockOn] = useState(false);
    const location = useLocation();

    const handleKeyUp = e => {
        const capsLockIsOn = e.getModifierState("CapsLock");
        setCapsLockOn(capsLockIsOn);
    };

    const handleSubmit = async event => {
        event.preventDefault();
        setLogggingIn(true);
        const data = new FormData(formRef.current);
        data.append("superuser", "true");
        setError(await onLogin(data, location.state?.from?.pathname));
        setLogggingIn(false);
    };

    return (
        <>
            <h1 className="text-light fw-bold mb-0 mt-xl-0 d-none d-xl-inline-block">Music Test by</h1>
            <LogoTitle className="mb-4 h-auto w-100 d-none d-xl-block"></LogoTitle>
            <h1 className="text-light fw-bold mb-0 mt-xl-0 d-xl-none mb-4 lh-sm text-center fs-1">Music Test by RadioAnalyzer</h1>
            <Form onSubmit={handleSubmit} ref={formRef} className=" mb-auto mb-xl-0">
                <InputGroup className="mb-2 ">
                    <InputGroup.Text className="text-blue bg-beige color-ra-dark-blue fs-4 p-2">
                        <PersonFill />
                    </InputGroup.Text>
                    <Form.Control className=" rounded-end" size="lg" required type="text" name="username" placeholder="Username" />
                </InputGroup>
                <InputGroup className="mb-4">
                    <InputGroup.Text className="text-blue bg-beige color-ra-dark-blue fs-4 p-2">
                        <LockFill />
                    </InputGroup.Text>
                    <Form.Control className="rounded-end" size="lg" required type="password" name="password" placeholder="Password" onKeyUp={handleKeyUp} />
                </InputGroup>
                {capsLockOn && <div className="text-warning mb-2">Caps Lock is on. Passwords are case-sensitive.</div>}

                <Stack direction="horizontal" gap={3} className=" flex-wrap-reverse">
                    <NavLink
                        to={"forgot_password"}
                        className="text-light link-underline-light link-underline-opacity-0 link-underline-opacity-75-hover font-weight-bold col-12 text-center col-xl-auto">
                        Forgot password?
                    </NavLink>
                    <Button
                        className="btn-ra-yellow text-uppercase fw-bold px-5 ms-auto col-12 fs-5 col-xl-auto"
                        size="sm"
                        disabled={logggingIn}
                        type={"submit"}>
                        Login
                    </Button>
                </Stack>
                <div className=" position-relative my-2 text-center text-xl-start">
                    <label className="text-warning login-error-message">{error}</label>
                </div>
            </Form>
            <Button
                onClick={() => window.open("https://radioanalyzer.com/client/", "_blank")}
                size="sm"
                className=" btn-ra-yellow d-xl-none mt-auto mb-3 mb-sm-0">
                Looking for RadioAnalyzer PRO? Login here
            </Button>
        </>
    );
}
