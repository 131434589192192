import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { axiosInstance } from "../../auth/Auth";
import moment from "moment/min/moment-with-locales";
import LoadingIndicator from "../LoadingIndicator";
import { BugFill } from "react-bootstrap-icons";

export default function LatestDataRow({ radio, dataType }) {
    const [latestAvailableDate, setLatestAvailableDate] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getLatestAvailableDate() {
            try {
                setLoading(true);
                const response = await axiosInstance.get(`/radios/${radio.radio_id}/latest_calculated_dates`);
                setLatestAvailableDate(response.data);
            } catch (error) {
                setLatestAvailableDate(null);
            } finally {
                setLoading(false);
            }
        }
        getLatestAvailableDate();
    }, [radio]);

    const dataTypeKey = useMemo(() => (dataType === 1 ? "stream" : "ppm"), [dataType]);
    const formatDate = date => (date ? moment(date).format("L").slice(0, -5) : "-");
    const renderDateColumn = columnKey => {
        if (latestAvailableDate === null) {
            return <BugFill className=" text-danger" />;
        }
        return formatDate(latestAvailableDate?.[dataTypeKey]?.[columnKey]);
    };

    return (
        <Row className="py-1 border-bottom align-items-center fs-8-xs">
            <Col xs={4} className="align-content-center">
                {radio.radio_name}
            </Col>
            {loading ? (
                <Col>
                    <LoadingIndicator width={20} showText={false} />
                </Col>
            ) : (
                <Col className=" align-content-center text-center">{renderDateColumn("song_deltas")} </Col>
            )}
        </Row>
    );
}
